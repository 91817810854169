import { Action, Dispatch } from 'redux';
import { companiesConstants } from '../../constants/companies.constants';
import { CompaniesService } from '../../services/company.service';
import { CompaniesInterface, CreateCompaniesInterface } from '../../types/companies.type';
import { UserInterface, UserLimitsInterface, UserPermissionsInterface } from '../../types/user.type';
import { balanceHistoryConstants } from '../../constants/balance_history.constants';
import { BalanceHistoryInterface, BalanceHistoryInterfacePaged } from '../../types/balance_history.type';
import { TransactionsInterfacePaged } from '../../types/transactions.type';
import { transactionsConstants } from '../../constants/transactions.constants';

const listCompanies = () => {
    const success = (companies: CompaniesInterface[]) => {
        return {type: companiesConstants.LIST_COMPANIES, companies}
    }

    return async (dispatch: Dispatch<Action>) => {
        try {
            const data = await CompaniesService.listCompanies()
            dispatch(success(data))
        } catch (error) {
            throw error;
        }
    }
}

const getCompanyDetails = (companyId: number) => {
    return async () => {
        try {
            const data = await CompaniesService.getCompanyDetails(companyId)
            return data
        } catch (error) {
            throw error;
        }
    }
}

const createCompany = async (company: CreateCompaniesInterface) => {
    try {
        const data = await CompaniesService.createCompany(company)
        return data
    } catch (error) {
        throw error;
    }
}

const createUser = async (company_id: number, user: UserInterface) => {
    try {
        const response = await CompaniesService.createUser(company_id, user)
        return response
    } catch (error) {
        throw error
    }
}

const associateUser = async (company_id: number, user: {user_id?: number, limits?: UserLimitsInterface[], permissions?: UserPermissionsInterface}) => {
    try {
        const response = await CompaniesService.associateUser(company_id, user)
        return response
    } catch (error) {
        throw error
    }
}

const checkUser = async (payload: {email: string, company_id: number}) => {
    try {
        const response = await CompaniesService.checkUser(payload)
        return response
    } catch (error) {
        throw error
    }
}

const disassociateUser = async (company_id: number, user:{user_id:number}) => {
    try {
        const response = await CompaniesService.disassociateUser(company_id, user)
        return response
    } catch (error) {
        throw error
    }
}

const laodBalanceHistory = (company_id?: number, page?: number) => {
    const success = (balance_history: BalanceHistoryInterfacePaged) => {
        return { type: balanceHistoryConstants.LOAD_BALANCE_HISTORY, balance_history };
    };

    return async (dispatch: Dispatch<Action>) => {
        try {
            const data = await CompaniesService.getUserBalanceHistoryPaged(company_id, page);
            dispatch(success(data));
        } catch (error) {
            throw error;
        }
    };
}

const listCertificatesPaged = (company_id?: number, page?: number) => {
    const success = (certificates: BalanceHistoryInterfacePaged) => {
        return { type: balanceHistoryConstants.LOAD_CERTIFICATES_PAGED, certificates };
    };

    return async (dispatch: Dispatch<Action>) => {
        try {
            const data = await CompaniesService.listCertificatesPaged(company_id, page);
            dispatch(success(data));
        } catch (error) {
            throw error;
        }
    };
}

const loadTransactions = (company_id?: number, page?:number) => {
    const success = (transactions: TransactionsInterfacePaged) => {
        return { type: transactionsConstants.LOAD_TRANSACTIONS, transactions };
    };

    return async (dispatch: Dispatch<Action>) => {
        try {
            const data = await CompaniesService.getTransactionsPaged(company_id, page);
            dispatch(success(data));
        } catch (error) {
            throw error;
        }
    };
}

const loadPixDeposit = (company_id?: number) => {
    const success = (pix_deposit: BalanceHistoryInterface[]) => {
        return { type: transactionsConstants.LOAD_PIX_DEPOIST, pix_deposit };
    };

    return async (dispatch: Dispatch<Action>) => {
        try {
            const data = await CompaniesService.getPixDeposit(company_id);
            dispatch(success(data));
        } catch (error) {
            throw error;
        }
    };
}

const loadMonthTotals = async (company_id?: number) => {
    try {
        const data = await CompaniesService.loadMonthTotals(company_id);
        return data
    } catch (error) {
        throw error;
    }
}

const loadMonthHeaders = async (company_id?: number) => {
    try {
        const data = await CompaniesService.loadMonthHeaders(company_id);
        return data
    } catch (error) {
        throw error;
    }
}

const exportBalancehistory =  (company_id: number) => {
    return async () => {
        try {
            const response = await CompaniesService.exportBalancehistory(company_id)
            debugger
            return response
        } catch (error) {
            throw error
        }
    }
}

const updateBalance = (company_id: number, value:number, operation:string) => {
    return async () => {
        try {
            if (operation === 'sub') {
                value = -value
            }
            const response = await CompaniesService.updateBalance(company_id, value)
            return response
        } catch (error) {
            throw error
        }
    }
}

export const companiesActions = {
    checkUser,
    createUser,
    listCompanies,
    createCompany,
    associateUser,
    loadPixDeposit,
    loadMonthTotals,
    loadTransactions,
    loadMonthHeaders,
    disassociateUser,
    getCompanyDetails,
    laodBalanceHistory,
    listCertificatesPaged,
    exportBalancehistory,
    updateBalance
}