import { useState } from 'react'
import { Button, Col, Row, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { formatDate } from '../../helpers/utils';

import { TransactionsInterface, TransactionsInterfacePagination } from '../../types/transactions.type';

import TransactionsModal from '../TransactionsModal';

import { DownloadOutlined, PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import {ReactComponent as CalendarIcon} from '../../static/icon/calendar-green.svg';
import {ReactComponent as TransactionTypeIcon} from '../../static/icon/transaction-type.svg';
import {ReactComponent as MoneyIcon} from '../../static/icon/money-green.svg';

import {ReactComponent as VisaIcon} from '../../static/icon/visa.svg';
import {ReactComponent as MasterIcon} from '../../static/icon/master.svg';
import {ReactComponent as AmexIcon} from '../../static/icon/amex.svg';
import {ReactComponent as EloIcon} from '../../static/icon/elo.svg';
import {ReactComponent as HiperIcon} from '../../static/icon/hipercard.svg';
import {ReactComponent as PixIcon} from '../../static/icon/pix-green.svg';
import {ReactComponent as TicketIcon} from '../../static/icon/ticket.svg';

import './styles.css'

interface TransactionsTableProps {
  top?: number;
  minimal?: boolean;
  select?: string[];
  active_scroll?: boolean
  transactions: TransactionsInterface[];
  pagination?: TransactionsInterfacePagination;
  changePage?: (page: number) => void;
}

const TransactionsTable = ({ 
  top,
  select,
  minimal,
  active_scroll,
  transactions,
  pagination,
  changePage
}: TransactionsTableProps) => {
  const [openModal, setOpenModal] = useState<{open: boolean; transaction?: TransactionsInterface}>({open: false})
  
  interface IconsProps {
    "visa": JSX.Element,
    "visa electron": JSX.Element,
    "mastercard": JSX.Element,
    "maestro": JSX.Element,
    "amex": JSX.Element,
    "elo": JSX.Element,
    "elo credito": JSX.Element,
    "elo debito": JSX.Element,
    "hipercard crd": JSX.Element,
    "hipercard": JSX.Element,
    "pix": JSX.Element,
    "boleto": JSX.Element,
  }
  const icons:IconsProps = {
    "visa": <VisaIcon />,
    "visa electron": <VisaIcon />,
    "mastercard": <MasterIcon />,
    "maestro": <MasterIcon />,
    "amex": <AmexIcon />,
    "elo": <EloIcon />,
    "elo credito": <EloIcon />,
    "elo debito": <EloIcon />,
    "hipercard crd": <HiperIcon />,
    "hipercard": <HiperIcon />,
    "pix": <PixIcon />,
    "boleto": <TicketIcon />,
  }

  const columns: ColumnsType<TransactionsInterface> = [
    {//transaction_date
      key: 'transaction_date',
      dataIndex: ['transaction_date', 'transaction_time'],
      render: (text, record) => {
        const date = new Date(record.transaction_date + " " + record.transaction_time)
        return (
            <Col className={`balance-table-cell ${minimal && 'first-cell'}`}>
                <Row align='middle'>
                    <CalendarIcon />
                    <span className='balance-table-title'>Data da operação</span>
                </Row>
                <Row>
                    <span className='balance-table-value'>{formatDate(date)}</span>
                </Row>
            </Col>
        )
      }
    },
    {//financial_institute
      key: 'financial_institute',
      dataIndex: 'financial_institute',
      render: (record:string) => {
        return (
            <Col className='balance-table-cell'>
                <Row align='middle'>
                    <TransactionTypeIcon />
                    <span className='balance-table-title'>Bandeira</span>
                </Row>
                <Row>
                    <span className='balance-table-value'>{icons[record.toLocaleLowerCase() as keyof IconsProps]}</span>
                </Row>
            </Col>
        )
      }
    },
    {//installments
      key: 'installments',
      dataIndex: 'installments',
      render: (record:string) => {
        return (
            <Col className='balance-table-cell'>
                <Row align='middle'>
                    <MoneyIcon />
                    <span className='balance-table-title'>Parcelas</span>
                </Row>
                <Row>
                    <span className='balance-table-value'>{record}</span>
                </Row>
            </Col>
        )
      }
    },
    {//original_value
      key: 'original_value',
      dataIndex: 'original_value',
      render: (record:string) => {
        return (
          <Col className='balance-table-cell last-cell'>
              <Row align='middle'>
                  <MoneyIcon />
                  <span className='balance-table-title'>Valor bruto</span>
              </Row>
              <Row>
              <span className='balance-table-value' style={{color: Number(record) > 0 ? "#00C108" : "#FF0000"}}>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
              </Row>
          </Col>
        )
      }
    }
  ];

  const handeShowColumns = () => {
    if (select) {
      return columns.filter(item => {
        if (item.key && select.includes(String(item.key))) return item
      })
    } else {
      return columns
    }
  }

  return (
    <div>
      <TransactionsModal 
        open={openModal.open}
        transaction={openModal.transaction}
        onClose={() => setOpenModal({...openModal, open: false})}
      />
      <Table
        rowKey='id_banco'
        size="middle"
        className="transactions-table"
        showHeader={false}
        columns={handeShowColumns()}
        scroll={active_scroll ? { x: 1000, y: 600 } : undefined}
        dataSource={top && transactions ? transactions.slice(0, top) : transactions}
        expandable={minimal ? undefined : {
          expandIcon: ({expanded, onExpand, record}) => {
            return (
              <Col className='transactions-table-cell first-cell' style={{justifyContent: "center"}}>
                {expanded ? (
                  <MinusSquareOutlined style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)}/>
                ) : (
                  <PlusSquareOutlined  style={{ fontSize: 20, color: "#0A1E41" }} onClick={e => onExpand(record, e)} />
                )}
              </Col>
            )
          },
          expandedRowRender: (record) => {
            return (
              <div className='transactions-table-description'>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h3 className='transactions-table-description-title'>Estabelecimento</h3>
                  <span className='transactions-table-description-value'>Restaurante</span>
                  <span className='transactions-table-description-value'>Belo Horizonte - MG</span>
                  <span className='transactions-table-description-value'>CNPJ 00.000.000/0001-00</span>
                </div>
                <div>
                  <span className='transactions-table-description-value'>
                    Cartão:
                    <span> Final 0000</span>
                  </span>
                </div>
                <div>
                  <Button className='transactions-table-refuse' onClick={() => setOpenModal({open: true, transaction: record})}>Cancelar venda</Button>
                  <Tooltip placement="bottom" title={"Baixar comprovante"} color="#00A19B">
                    <Button 
                      type="ghost" 
                      shape="circle" 
                      icon={<DownloadOutlined style={{fontSize: 25}}/>} 
                      size="large" 
                      className='transactions-table-upload'
                    />
                  </Tooltip>
                </div>
              </div>
            )
          }
        }}
        pagination={!changePage ? false : {
          position: ['bottomCenter'],
          total: pagination ? (pagination.pages * pagination.per_page * 2) : 0,
          showSizeChanger: false,
          onChange(page) {
            changePage(page)
          },
        }}
      />
    </div>
  );
};

export default TransactionsTable;
